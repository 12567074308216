import React from "react";
import { graphql } from 'gatsby';

import Layout from "../components/layout"
import SEO from "../components/seo";

const SubscribePage = ({ pageContext, data }) => {
    return (
        <Layout 
            {...pageContext} 
            subscribeTitle={data.subscribeTitle}
            subscribeSubtitle={data.subscribeSubtitle}
        >
            <SEO 
                lang={pageContext.lang}
                path={pageContext.pageUrl}
                title={data.seoTitleText}
                description={data.seoDescriptionText}
            />
            <div style={{ height: '70px', display: 'block' }} />
        </Layout>
    );
}


export default SubscribePage;

export const pageQuery = graphql`
    query SubscribePage($lang: String!) {
        seoTitleText: getTranslation(lang: $lang, key: "subscribe_seo_title")
        seoDescriptionText: getTranslation(lang: $lang, key: "subscribe_seo_description")

        subscribeTitle: getTranslation(lang: $lang, key: "subscribe_page_title")
        subscribeSubtitle: getTranslation(lang: $lang, key: "subscribe_page_subtitle")
    }
`